<template>
  <div>
    <v-card>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="$store.state.device.field.agent"
          placeholder="Rechercher"
          label="Rechercher"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keyup="filterDevices"
        >
        </v-text-field>

        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
      <v-data-table
        loading-text="Chargement..."
        no-data-text="Aucun résultat"
        no-results-text="Aucun résultat"
        :headers="headers"
        :items="$store.getters['device/devices']"
        :items-per-page="$store.getters['device/per_page']"
        :page.sync="$store.getters['device/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        :loading="loading"
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>
        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>
        <template #[`item.deleted_at`]="{item}">
          {{ item.deleted_at !== null ? formattingDate(`${item.deleted_at}`) : null }}
        </template>
        <template #[`item.expired_at`]="{item}">
          {{ item.expired_at !== null ? formattingDate(`${item.expired_at}`) : null }}
        </template>

        <template #[`item.is_active`]="{item}">
          <v-chip
            small
            :color="item.is_active === true ? 'success' : 'error'"
          >
            {{ item.is_active === true ? "OUI" : "NON" }}
          </v-chip>
        </template>
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title
                  @click.stop="showToken(item.key)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Voir le jeton</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link>
                <v-list-item-title
                  @click.stop="confirmItem(item)"
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteForever }}
                  </v-icon>
                  <span>Supprimer définitivement</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="$store.state.device.field.paginate"
              :items="itemsPerPage"
              item-text="text"
              item-value="value"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getEltsPerPage"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getElts"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <device-token-dialog
      :token="contentToken"
      :dialog-content="showDialogContent"
      @clickout="showDialogContent = false"
    />
    <confirm-dialog
      :message="messageDialog"
      :dialog-delete="dialogDelete"
      @clickout="dialogDelete = false"
      @submitted="deleteItem"
    />
  </div>
</template>

<script>
import {
  onMounted, ref, watch, computed,
} from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import DeviceTokenDialog from '@core/components/dialogs/DeviceTokenDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import { notify } from '@core/utils/notification'
import store from '@/store'

export default {
  name: 'UserDevicesList',
  components: { ConfirmDialog, DeviceTokenDialog },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    user_id: {
      type: String,
      default: null,
    },
    isRefreshed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const loading = ref(false)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const isDialogVisible2 = ref(false)
    const dialog = ref(false)
    const contentToken = ref(null)
    const error = ref(null)
    const showDialogContent = ref(false)
    const messageDialog = ref('')
    const message = ref('')
    const item = ref({})

    const formattingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const getElts = (page = 1) => {
      loading.value = true
      store.state.device.field.user_id = props.user_id
      store.dispatch('device/getDevices', { page, field: store.state.device.field }).then(() => {
        currentPage.value = store.getters['device/current_page']
        lastPage.value = store.getters['device/last_page']
        loading.value = false
      })
    }

    const filterElts = () => {
      setTimeout(() => {
        getElts()
      }, 1000)
    }

    // eslint-disable-next-line camelcase
    const getEltsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        loading.value = true
        // eslint-disable-next-line camelcase
        store.state.device.field.paginate = per_page
        store.dispatch('device/getDevices', {
          page: store.getters['device/current_page'],
          field: store.state.device.field,
        }).then(() => {
          currentPage.value = store.getters['device/current_page']
          lastPage.value = store.getters['device/last_page']
          loading.value = false
        })
      }
    }

    onMounted(() => {
      setTimeout(() => {
        getElts(store.getters['device/current_page'])
      }, 500)
    })

    const filterDevices = () => {
      setTimeout(() => {
        getElts(store.getters['device/current_page'])
      }, 1000)
    }

    const showToken = val => {
      contentToken.value = val
      showDialogContent.value = true
    }

    const deleteItem = () => {
      store.dispatch('device/forceDeleteDevice', item.value).then(response => {
        dialogDelete.value = false
        notify(true, true, response.message)
      }).catch(err => {
        message.value = err.response.data.message
        error.value = err.response.data.message
        dialogDelete.value = false
        notify(true, true, err.response.data.message)
      })
    }

    const confirmItem = object => {
      messageDialog.value = `Voulez-vous supprimer définitivement l'appareil ${object.agent}?`
      item.value = object
      dialogDelete.value = true
    }

    watch(() => props.isRefreshed, newValue => {
      setTimeout(() => {
        getElts(store.getters['device/current_page'])
      }, 1000)
    })

    return {
      item,
      confirmItem,
      deleteItem,
      messageDialog,
      message,
      error,
      showToken,
      contentToken,
      showDialogContent,
      filterDevices,
      filterElts,
      formattingDate,
      closeDialog,
      loading,
      getEltsPerPage,
      getElts,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      isDialogVisible2,
      headers: [
        { text: 'APPAREIL', value: 'agent', sortable: false },
        { text: 'ACTIF?', value: 'is_active', sortable: false },
        { text: 'EXPIRE LE', value: 'expired_at', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      itemsPerPage: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
      ],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
