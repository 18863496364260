, sortable: false<template>
  <v-card>
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="$store.state.video.field.title"
        placeholder="Rechercher par mot clé"
        label="Rechercher par mot clé"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
        @keyup="filterVideos"
      >
      </v-text-field>

      <v-spacer></v-spacer>
    </v-card-text>

    <!-- table -->
    <v-data-table
      loading-text="Chargement..."
      no-data-text="Aucun résultat"
      no-results-text="Aucun résultat"
      :headers="headers"
      :items="$store.getters['video/videos']"
      :items-per-page="$store.getters['video/per_page']"
      :page.sync="$store.getters['video/current_page']"
      dense
      fixed-header
      height="500"
      hide-default-footer
      :loading="loading"
      @page-count="currentPage = $event"
    >
      <template #[`item.created_at`]="{item}">
        {{ formattingDate(`${item.created_at}`) }}
      </template>
      <template #[`item.updated_at`]="{item}">
        {{ formattingDate(`${item.updated_at}`) }}
      </template>
      <template #[`item.deleted_at`]="{item}">
        {{ item.deleted_at !== null ? formattingDate(`${item.deleted_at}`) : null }}
      </template>

      <template #[`item.with_push_notif`]="{item}">
        <v-chip
          small
          :color="item.with_push_notif === true ? 'success' : 'error'"
        >
          {{ item.with_push_notif === true ? "OUI" : "NON" }}
        </v-chip>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="2"
          cols="3"
        >
          <v-select
            v-model="$store.state.video.field.paginate"
            :items="itemsPerPage"
            item-text="text"
            item-value="value"
            label="Lignes par page:"
            type="number"
            min="-1"
            max="15"
            hide-details
            @input="getEltsPerPage"
          ></v-select>
        </v-col>

        <v-col
          lg="10"
          cols="9"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="currentPage"
            total-visible="6"
            :length="lastPage"
            @input="getElts"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAccountLock,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { customFormatDate } from '@core/utils/filter'
import store from '@/store'
import {watch} from "@vue/composition-api/dist/vue-composition-api";

export default {
  name: 'UserVideosList',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    user_id: {
      type: String,
      default: null,
    },
    isRefreshed: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const isLockedColor = {
      false: 'info',
      true: 'success',
    }
    const loading = ref(false)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const dialogDelete = ref(false)
    const isDialogVisible = ref(false)
    const isDialogVisible2 = ref(false)
    const dialog = ref(false)

    const formattingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const closeDialog = () => {
      dialog.value = false
    }

    const getElts = (page = 1) => {
      loading.value = true
      store.state.video.field.user_id = props.user_id
      store.state.video.field.user = null
      store.dispatch('video/getVideos', { page, field: store.state.video.field }).then(() => {
        currentPage.value = store.getters['video/current_page']
        lastPage.value = store.getters['video/last_page']
        loading.value = false
      })
    }

    const filterElts = () => {
      setTimeout(() => {
        getElts()
      }, 1000)
    }

    // eslint-disable-next-line camelcase
    const getEltsPerPage = per_page => {
      // eslint-disable-next-line camelcase
      if (per_page !== null) {
        loading.value = true
        // eslint-disable-next-line camelcase
        store.state.video.field.paginate = per_page
        store.state.video.field.user_id = props.user_id
        store.dispatch('video/getVideos', {
          page: store.getters['video/current_page'],
          field: store.state.video.field,
        }).then(() => {
          currentPage.value = store.getters['video/current_page']
          lastPage.value = store.getters['video/last_page']
          loading.value = false
        })
      }
    }

    onMounted(() => {
      setTimeout(() => {
        getElts(store.getters['video/current_page'])
      }, 500)
    })

    const filterVideos = () => {
      setTimeout(() => {
        getElts(store.getters['video/current_page'])
      }, 1000)
    }

    watch(() => props.isRefreshed, newValue => {
      setTimeout(() => {
        getElts(store.getters['video/current_page'])
      }, 1000)
    })

    return {
      filterVideos,
      filterElts,
      formattingDate,
      closeDialog,
      loading,
      getEltsPerPage,
      getElts,
      currentPage,
      lastPage,
      dialog,
      dialogDelete,
      isDialogVisible,
      isDialogVisible2,
      headers: [
        { text: 'TITRE', value: 'title', sortable: false },
        { text: 'URL', value: 'url', sortable: false },
        { text: 'AVEC NOTIFICATION PUSH', value: 'with_push_notif', sortable: false },
        { text: 'DATE DE CREATION', value: 'created_at', sortable: false },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at', sortable: false },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at', sortable: false },
      ],
      itemsPerPage: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
      ],
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      is_locked: {
        1: true,
        2: false,
      },
      isLockedColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiPlus,
        mdiEyeOutline,
      },
    }
  },

}
</script>

<style scoped>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
