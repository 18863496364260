var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Rechercher","label":"Rechercher","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.filterDevices},model:{value:(_vm.$store.state.device.field.agent),callback:function ($$v) {_vm.$set(_vm.$store.state.device.field, "agent", $$v)},expression:"$store.state.device.field.agent"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"loading-text":"Chargement...","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat","headers":_vm.headers,"items":_vm.$store.getters['device/devices'],"items-per-page":_vm.$store.getters['device/per_page'],"page":_vm.$store.getters['device/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'device/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.updated_at))))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted_at !== null ? _vm.formattingDate(("" + (item.deleted_at))) : null)+" ")]}},{key:"item.expired_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expired_at !== null ? _vm.formattingDate(("" + (item.expired_at))) : null)+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.is_active === true ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.is_active === true ? "OUI" : "NON")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.stopPropagation();return _vm.showToken(item.key)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Voir le jeton")])],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.stopPropagation();return _vm.confirmItem(item)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteForever)+" ")]),_c('span',[_vm._v("Supprimer définitivement")])],1)],1)],1)],1)]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPage,"item-text":"text","item-value":"value","label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getEltsPerPage},model:{value:(_vm.$store.state.device.field.paginate),callback:function ($$v) {_vm.$set(_vm.$store.state.device.field, "paginate", $$v)},expression:"$store.state.device.field.paginate"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getElts},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('device-token-dialog',{attrs:{"token":_vm.contentToken,"dialog-content":_vm.showDialogContent},on:{"clickout":function($event){_vm.showDialogContent = false}}}),_c('confirm-dialog',{attrs:{"message":_vm.messageDialog,"dialog-delete":_vm.dialogDelete},on:{"clickout":function($event){_vm.dialogDelete = false},"submitted":_vm.deleteItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }