var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Rechercher par mot clé","label":"Rechercher par mot clé","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.filterCommunications},model:{value:(_vm.$store.state.communication.field.keyword),callback:function ($$v) {_vm.$set(_vm.$store.state.communication.field, "keyword", $$v)},expression:"$store.state.communication.field.keyword"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"loading-text":"Chargement...","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat","headers":_vm.headers,"items":_vm.$store.getters['communication/communications'],"items-per-page":_vm.$store.getters['communication/per_page'],"page":_vm.$store.getters['communication/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'communication/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.highlight_image ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.highlight_image ? '' : 'primary',"size":"32"}},[(item.highlight_image)?_c('v-img',{attrs:{"src":("" + (item.highlight_image))}}):(item.title)?_c('span',[_vm._v(_vm._s(item.title !== null && item.title !== undefined ? item.title.slice(0,2).toUpperCase() : null))]):_c('v-img',{attrs:{"src":require('@/assets/images/avatars/1.png')}})],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.title))])])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatingDate(("" + (item.updated_at))))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted_at !== null ? _vm.formatingDate(("" + (item.deleted_at))) : null)+" ")]}},{key:"item.with_push_notif",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.with_push_notif === true ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.with_push_notif === true ? "OUI" : "NON")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getCommunicationDetails(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Consulter")])])]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPage,"item-text":"text","item-value":"value","label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getEltsPerPage},model:{value:(_vm.$store.state.communication.field.paginate),callback:function ($$v) {_vm.$set(_vm.$store.state.communication.field, "paginate", $$v)},expression:"$store.state.communication.field.paginate"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getElts},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }