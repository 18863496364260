<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-progress-linear
          :active="isDialogVisible"
          :indeterminate="isDialogVisible"
          absolute
          top
          color="primary"
        ></v-progress-linear>
        <v-card-title>
          <span class="headline">{{ item.id !== null ? "Modifier utilisateur" : "Nouveau utilisateur" }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.email"
                    type="text"
                    label="Email"
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="item.role_id"
                    :items="$store.getters['role/roles']"
                    item-text="label"
                    item-value="id"
                    label="Role"
                    type="text"
                    :rules="[rules.role_id]"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.name"
                    type="text"
                    label="Nom"
                    :rules="[rules.name]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.last_name"
                    type="text"
                    label="Prénom"
                    :rules="[rules.last_name]"
                  ></v-text-field>
                </v-col>
              </v-row>
<!--              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.phone_number"
                    type="text"
                    label="Téléphone"
                    :rules="[rules.phone_number]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="item.civility"
                    :items="civilities"
                    item-text="text"
                    item-value="value"
                    label="Civitlité"
                    type="text"
                    :rules="[rules.civility]"
                  ></v-select>
                </v-col>
              </v-row>-->
              <v-row >
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.password"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="Mot de passe"
                    :rules="[rules.password]"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="item.password_confirmation"
                    label="Confirmer mot de passe"
                    :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                    :append-icon="isPasswordConfirmationVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :rules="[rules.password_confirmation]"
                    @click:append="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              {{ item.id !== null ? "Modifier" : "Enregistrer" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { notify } from '@core/utils/notification'
import store from '@/store'

export default {
  name: 'UserForm',

  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    object: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const color = ref(null)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)

    const rules = {
      name: true,
      last_name: true,
      email: true,
      phone_number: true,
      civility: true,
      password: true,
      password_confirmation: true,
      bio: true,
      role_id: true,
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      rules.name = true
      rules.last_name = true
      rules.email = true
      rules.phone_number = true
      rules.password = true
      rules.password_confirmation = true
      rules.bio = true
      rules.role_id = true
      instance.emit('clickout')
    }

    watch(() => props.dialog, dialog => {
      // eslint-disable-next-line no-unused-expressions
      dialog || close()
    })

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true

      if (item.value.id !== null) {
        store.dispatch('user/updateUser', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.name = error.response.data.errors.name !== undefined ? error.response.data.errors.name[0] : true
            rules.last_name = error.response.data.errors.last_name !== undefined ? error.response.data.errors.last_name[0] : true
            rules.email = error.response.data.errors.email !== undefined ? error.response.data.errors.email[0] : true
            rules.phone_number = error.response.data.errors.phone_number !== undefined ? error.response.data.errors.phone_number[0] : true
            rules.civility = error.response.data.errors.civility !== undefined ? error.response.data.errors.civility[0] : true
            rules.role_id = error.response.data.errors.role_id !== undefined ? error.response.data.errors.role_id[0] : true
            rules.password = error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : true
            rules.password_confirmation = error.response.data.errors.password_confirmation !== undefined ? error.response.data.errors.password_confirmation[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      } else {
        store.dispatch('user/addUser', item.value).then(response => {
          isDialogVisible.value = false
          notify(true, true, response.message)
          instance.emit('clickout')
        }).catch(error => {
          form.value.validate()
          message.value = error.response.data.message
          errors.value = error.response.data.errors
          if (error.response.data.errors !== undefined) {
            rules.name = error.response.data.errors.name !== undefined ? error.response.data.errors.name[0] : true
            rules.last_name = error.response.data.errors.last_name !== undefined ? error.response.data.errors.last_name[0] : true
            rules.email = error.response.data.errors.email !== undefined ? error.response.data.errors.email[0] : true
            rules.phone_number = error.response.data.errors.phone_number !== undefined ? error.response.data.errors.phone_number[0] : true
            rules.civility = error.response.data.errors.civility !== undefined ? error.response.data.errors.civility[0] : true
            rules.role_id = error.response.data.errors.role_id !== undefined ? error.response.data.errors.role_id[0] : true
            rules.password = error.response.data.errors.password !== undefined ? error.response.data.errors.password[0] : true
            rules.password_confirmation = error.response.data.errors.password_confirmation !== undefined ? error.response.data.errors.password_confirmation[0] : true
          }
          isDialogVisible.value = false
          isAlertVisible.value = true
        })
      }
    }

    const getRoles = (page = 1) => {
      store.dispatch('role/getRoles', { page, field: store.state.role.field }).catch(error => {
        console.log(error)
      })
    }

    onMounted(() => {
      getRoles()
    })

    return {
      close,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      item,
      color,
      civilities: [
        { text: 'Mr', value: 'Mr' },
        { text: 'Mme', value: 'Mme' },
      ],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
