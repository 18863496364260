<template>
  <div>
    <v-dialog
      v-model="dialogContent"
      max-width="700"
      @click:outside="closeDialogContent"
      @keydown.esc="closeDialogContent"
    >
      <v-card >
        <v-card-text>
          {{ token}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDialogContent"
          >
            Fermer
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api/dist/vue-composition-api'

export default {
  name: 'DeviceTokenDialog',

  emits: ['clickout', 'submitted'],

  props: {
    dialogContent: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: null,
    },
  },

  setup() {
    const instance = getCurrentInstance()

    const closeDialogContent = () => {
      instance.emit('clickout')
    }

    return {
      closeDialogContent,
    }
  },
}
</script>

<style scoped>
.linkContent {
  width: 700px;
  height: 500px;
}

@media only screen and (max-width: 600px)  {
  .linkContent {
    width: 325px;
    height: 250px;
  }
}
</style>
