var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Rechercher par mot clé","label":"Rechercher par mot clé","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.filterVideos},model:{value:(_vm.$store.state.video.field.title),callback:function ($$v) {_vm.$set(_vm.$store.state.video.field, "title", $$v)},expression:"$store.state.video.field.title"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"loading-text":"Chargement...","no-data-text":"Aucun résultat","no-results-text":"Aucun résultat","headers":_vm.headers,"items":_vm.$store.getters['video/videos'],"items-per-page":_vm.$store.getters['video/per_page'],"page":_vm.$store.getters['video/current_page'],"dense":"","fixed-header":"","height":"500","hide-default-footer":"","loading":_vm.loading},on:{"update:page":function($event){return _vm.$set(_vm.$store.getters, 'video/current_page', $event)},"page-count":function($event){_vm.currentPage = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.created_at))))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattingDate(("" + (item.updated_at))))+" ")]}},{key:"item.deleted_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.deleted_at !== null ? _vm.formattingDate(("" + (item.deleted_at))) : null)+" ")]}},{key:"item.with_push_notif",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.with_push_notif === true ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.with_push_notif === true ? "OUI" : "NON")+" ")])]}}],null,true)}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPage,"item-text":"text","item-value":"value","label":"Lignes par page:","type":"number","min":"-1","max":"15","hide-details":""},on:{"input":_vm.getEltsPerPage},model:{value:(_vm.$store.state.video.field.paginate),callback:function ($$v) {_vm.$set(_vm.$store.state.video.field, "paginate", $$v)},expression:"$store.state.video.field.paginate"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.lastPage},on:{"input":_vm.getElts},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }