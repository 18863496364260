<template>
  <div>
    <v-card class="mb-3">
      <v-card-title>
        <span>Détails de l'utilisateur</span>
        <v-spacer></v-spacer>
        <v-tooltip
          left
          color="primary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              class="me-n3 mt-n2"
              v-bind="attrs"
              icon
              v-on="on"
              @click="prev"
            >
              <v-icon>
                {{ icons.mdiArrowLeftBoldCircleOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>Précédent</span>
        </v-tooltip>
        <v-tooltip
          bottom
          color="secondary"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="secondary"
              class="me-n3 mt-n2 ml-2"
              v-bind="attrs"
              icon
              v-on="on"
              @click="refreshObjet"
            >
              <v-icon>
                {{ icons.mdiRefresh }}
              </v-icon>
            </v-btn>
          </template>
          <span>Raffraichir</span>
        </v-tooltip>
      </v-card-title>
    </v-card><!--
    <loader-dialog :is-dialog-visible="isDialogVisible" />-->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          class="mx-auto border"
          max-width="500"
          type="card, divider, list-item-three-line, list-item-three-line, list-item-three-line, list-item, actions"
        ></v-skeleton-loader>
        <user-bio-panel
          v-show="!isDialogVisible"
          :user="$store.state.user.user"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          class="mx-auto border"
          type="actions, divider, table-row, table-thead, table"
        ></v-skeleton-loader>
        <v-tabs
          v-show="!isDialogVisible"
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-show="!isDialogVisible"
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-videos-list :user_id="$store.state.user.user.id" :is-refreshed="isRefreshed"/>
          </v-tab-item>
          <v-tab-item>
            <user-qualities-list :user_id="$store.state.user.user.id" :is-refreshed="isRefreshed"/>
          </v-tab-item>
          <v-tab-item>
            <user-communications-list :user_id="$store.state.user.user.id" :is-refreshed="isRefreshed"/>
          </v-tab-item>
          <v-tab-item>
            <user-devices-list :user_id="$store.state.user.user.id" :is-refreshed="isRefreshed"/>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import {
  mdiCar,
  mdiDevices,
  mdiRefresh, mdiArrowLeftBoldCircleOutline, mdiQualityHigh, mdiVideoOutline,

} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import UserBioPanel from '@core/components/user/UserBioPanel'
import UserVideosList from '@core/components/user/UserVideosList'
import UserQualitiesList from '@core/components/user/UserQualitiesList'
import UserCommunicationsList from '@core/components/user/UserCommunicationsList'
import router from '@/router'
import store from '@/store'
import UserDevicesList from "@core/components/user/UserDevicesList";

export default {
  name: 'UserDetails',
  components: {
    UserDevicesList,
    UserCommunicationsList, UserQualitiesList, UserVideosList, UserBioPanel, LoaderDialog,
  },
  setup() {
    const userTab = ref(null)
    const isDialogVisible = ref(false)
    const isRefreshed = ref(false)
    const route = getCurrentInstance().proxy.$route

    const getUser = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        if (store.state.user.user === null || store.state.user.user.id !== id) {
          isDialogVisible.value = true
          store.dispatch('user/getUser', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
            router.back()
          })
        }
      }
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('user/getUser', id).then(() => {
          isDialogVisible.value = false
          isRefreshed.value = !isRefreshed.value
        }).catch(error => {
          console.log(error)
          router.back()
        })
      }
    }

    const tabs = [
      { icon: mdiVideoOutline, title: 'Vidéos' },
      { icon: mdiQualityHigh, title: 'Qualités' },
      { icon: mdiCar, title: 'Communications' },
      { icon: mdiDevices, title: 'Appareils' },
    ]

    const prev = () => {
      router.back()
    }

    onMounted(() => {
      getUser()
    })

    return {
      tabs,
      userTab,
      refreshObjet,
      isRefreshed,
      isDialogVisible,
      getUser,
      prev,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
