<template>
  <v-row class="user-bio-panel">
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-alert
          v-model="isAlertVisible"
          :color="error ? 'error' : 'success'"
          dismissible
          text
          transition="slide-y-transition"
        >
          {{ reponseMsg }}
        </v-alert>
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="user.profile_picture_path ? '' : 'primary'"
            :class="user.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="user.profile_picture_path"
              :src="user.profile_picture_path"
              contain
            ></v-img>
            <span
              v-else-if="user.name"
              class="font-weight-semibold text-5xl"
            >{{ avatarText(user.name) }}</span>
            <v-img
              v-else
              :src="require('@/assets/images/avatars/1.png')"
            />
          </v-avatar>

          <span class="mb-2">{{ user.name }} {{ user.last_name }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(user.role.code)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(user.role.code)}--text text-capitalize`"
          >
            {{ user.role.label }}
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Détails
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccount }}</v-icon>
                Nom:
              </span>
              <span class="text--secondary font-weight-bold">{{ user.name }} {{ user.last_name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">
                <v-icon size="15">{{ icons.mdiEmail }}</v-icon>
                Email:
              </span>
              <span class="text--secondary font-weight-bold">{{ user.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccountQuestion }}</v-icon>
                Role:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">{{ user.role.label }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiBellAlert }}</v-icon>
                Avec notification?
              </span>
              <span class="text&#45;&#45;secondary text-capitalize font-weight-bold"><v-chip
                small
                :color="user.is_notif_active === true ? 'success' : 'error'"
              >
                {{ user.is_notif_active === true ? "OUI" : "NON" }}
              </v-chip></span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiLogin }}</v-icon>
                Dernière connexion:
              </span>
              <span class="text--secondary font-weight-bold">{{ user.last_logged_at !== null ? formattingDate(user.last_logged_at) : null }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-tooltip
            color="info"
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                small
                class="mb-4 me-3 mt-4"
                v-bind="attrs"
                color="info"
                outlined
                v-on="on"
                @click="openForm"
              >
                <v-icon
                  dark
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
      <user-form
        :dialog="dialog"
        :object="user"
        @clickout="closeDialog"
        @submitted="loading"
      />
    </v-col>
  </v-row>
</template>

<script>
import { avatarText, customFormatDate, kFormatter } from '@core/utils/filter'
import UserForm from '@core/components/user/UserForm'
import { ref } from '@vue/composition-api'
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
  mdiAccountLock,
  mdiLockOpen, mdiPencilOutline,
  mdiAccount, mdiPhone, mdiEmail,
  mdiCalendar, mdiCalendarMinus, mdiCalendarEdit, mdiCakeVariant,
  mdiAccountQuestion, mdiTranslate, mdiLogin, mdiEmailCheck,
  mdiPhoneCheck, mdiFileCheck, mdiTextAccount, mdiThumbUp, mdiCheckDecagram, mdiHelp, mdiSmokingOff,
  mdiWalletTravel, mdiLock, mdiBellOutline, mdiBellOffOutline, mdiBellAlert
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import store from '@/store'

export default {
  name: 'UserBioPanel',
  components: { LoaderDialog, UserForm },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const isAlertVisible = ref(false)
    const dialogConfirm = ref(false)
    const dialogLock = ref(false)
    const loaderDialog = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const error = ref(false)
    const message = ref(null)
    const reponseMsg = ref(null)

    const resolveUserRoleVariant = role => {
      if (role === 'MEMBER') return 'primary'
      if (role === 'ADMIN') return 'success'

      return 'primary'
    }

    const formattingDate = date => customFormatDate(date, {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const submitting = () => {
      loaderDialog.value = true
    }

    const errorForm = () => {
      loaderDialog.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const openForm = () => {
      store.state.user.user.birthday = new Date(store.state.user.user.birthday).toISOString().substr(0, 10)
      dialog.value = true
    }

    return {
      isAlertVisible,
      dialogConfirm,
      dialogLock,
      loaderDialog,
      isDialogVisible,
      dialog,
      error,
      message,
      reponseMsg,
      formattingDate,
      loading,
      submitting,
      errorForm,
      closeDialog,
      openForm,
      avatarText,
      kFormatter,
      resolveUserRoleVariant,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountLock,
        mdiLockOpen,
        mdiPencilOutline,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiCakeVariant,
        mdiAccountQuestion,
        mdiTranslate,
        mdiLogin,
        mdiEmailCheck,
        mdiPhoneCheck,
        mdiFileCheck,
        mdiTextAccount,
        mdiThumbUp,
        mdiCheckDecagram,
        mdiHelp,
        mdiSmokingOff,
        mdiWalletTravel,
        mdiLock,
        mdiBellOutline,
        mdiBellOffOutline,
        mdiBellAlert,
      },
    }
  },
}
</script>

<style scoped>

</style>
